export const GoogleAnalytics = ({
  gaTrackingId,
}: {
  gaTrackingId: string | undefined
}) => {
  const loadGoogleAnalyticsScript = process.env.NODE_ENV !== "development" && gaTrackingId;

  return (
    <>
      {loadGoogleAnalyticsScript && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
          />
          <script
            async
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${gaTrackingId}', {
                page_path: window.location.pathname,
              });
            `,
            }}
          />
        </>
      )}
    </>
  );
};
