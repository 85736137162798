import {
  Form,
  useLocation,
  useSubmit,
} from "@remix-run/react";
import { FormName } from '~/shared/form-names';

const RenderForm = ({
  onNewsletterSubmit,
  redirectTo,
  showError,
}: {
  onNewsletterSubmit: () => void,
  redirectTo: string,
  showError: boolean,
}) => {
  return (
    <>
      <Form method="post">
        <div className="mb-4">
          <input
            aria-label="First name"
            name="firstName"
            type="text"
            placeholder="First name"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500"
            required
          />
        </div>
        <div className="mb-4">
          <input
            aria-label="Email"
            name="email"
            type="email"
            placeholder="Email"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500"
            required
          />
        </div>
        <button
          type="submit"
          onClick={() => onNewsletterSubmit()}
          className="w-full bg-green-700 text-white px-4 py-3 hover:bg-[#7cb342] focus:outline-none focus:ring-2 focus:ring-[#8bc34a] text-lg font-semibold"
        >
          Subscribe and Save 25%!
        </button>
        <input type="hidden" id="formName" name="formName" value={FormName.NEWSLETTER_SIGNUP} />
        <input type="hidden" id="redirectTo" name="redirectTo" value={redirectTo} />
      </Form>
      {showError && (
        <p className="text-red-600 my-2 font-bold">
          There was an error signing up. Please try again.
        </p>
      )}
    </>
  );
};

export const NewsletterSignup = ({
  onSubmit,
  showError,
  showSuccess,
}: {
  onSubmit: () => void,
  showError: boolean,
  showSuccess: boolean,
}) => {
  const submit = useSubmit();
  const location = useLocation();
  const redirectTo = location.pathname + location.search;
  const onNewsletterSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
    submit(event.currentTarget);
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onSubmit();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          onSubmit();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div 
        className="bg-[#faf3e0] p-8 max-w-md w-full relative" 
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        role="presentation"
      >
        {/* Close button */}
        <button onClick={() => onSubmit()} className="absolute top-2 right-4 text-gray-500 text-2xl">&times;</button>
        
        {/* Heading */}
        <h2 className="text-4xl font-bold mb-2">25% OFF</h2>
        <h3 className="text-xl font-bold text-red-600 mb-4 uppercase">Your first order</h3>
        
        {/* Image and text side by side */}
        <div className="flex mb-4">
          <img
            src="/images/shrimp-skewers-action-shot.webp"
            alt="Shrimp appetizer with wine"
            className="w-1/2 h-auto object-cover mr-4"
          />
          <div className="w-1/2">
            <p className="text-lg mb-4">Tasty recipes direct to your inbox!</p>
            <p className="text-lg">
              Sign up for our newsletter to receive a 25% discount code off your first online order!
            </p>
          </div>
        </div>
        
        {showSuccess ? (
          <p className="text-lg">
            Thanks for signing up! Keep an eye on your email for your discount code.
          </p>
        ) : (
          <RenderForm
            onNewsletterSubmit={onNewsletterSubmit}
            redirectTo={redirectTo}
            showError={showError}
          />
        )}
      </div>
    </div>
  );
};
