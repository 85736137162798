import { Form, Link, useLocation } from "@remix-run/react";
import { useState } from "react";
import { track } from '@vercel/analytics';
import { NewsletterSignup } from '~/components/newsletter-signup';

export function Header() {
  const [showNewsletterSubscribe, setShowNewsletterSubscribe] = useState(false);
  const location = useLocation();
  const showSuccess = location.search.includes('newsletter-signup=succeeded');
  const showError = location.search.includes('newsletter-signup=failed');
  
  const showNewsletter = () => {
    track('newsletter-popup');
    setShowNewsletterSubscribe(true);
  };

  return (
    <>
      <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <Link to="/" className="flex-shrink-0">
                <img
                  className="h-8 w-8"
                  src="/logo.png"
                  alt="Big Horn Olive Oil Logo"
                />
              </Link>
              <Link to="/" className="ml-3 text-2xl font-bold text-red-500">
                Recipes
              </Link>
              <div className="flex-shrink-0 px-4 h-full flex items-center relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-400 absolute left-7 top-1/2 transform -translate-y-1/2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <Form id="search-form" role="search">
                  <input
                    aria-label="Search"
                    id="ql"
                    name="ql"
                    type="search"
                    placeholder="What would you like to cook?"
                    className="max-sm:hidden w-96 pl-10 pr-4 h-10 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                  />
                </Form>
                <Form id="search-form" role="search">
                  <input
                    aria-label="Search"
                    id="qs"
                    name="qs"
                    type="search"
                    placeholder="What's cooking?"
                    className="sm:hidden w-64 md:w-96 pl-10 pr-4 h-10 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                  />
                </Form>
              </div>
            </div>
            <div className="flex items-center">
              <nav className="ml-6 flex space-x-4">
                {/* <Link to="/bbq-and-grilling" className="text-gray-600 hover:text-gray-900">
                  BBQ AND GRILLING
                </Link>
                <Link to="/grocery-list" className="text-gray-600 hover:text-gray-900">
                  YOUR GROCERY LIST
                </Link>
                <Link to="/recipe-box" className="text-red-500 hover:text-red-700">
                  YOUR RECIPE BOX
                </Link> */}
                <button onClick={() => showNewsletter()} className="text-red-500 hover:text-red-700 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <span className="hidden sm:inline">SUBSCRIBE</span>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {showNewsletterSubscribe && (
        <NewsletterSignup
          onSubmit={() => setShowNewsletterSubscribe(false)}
          showError={showError}
          showSuccess={showSuccess}
        />
      )}
    </>
  );
}
