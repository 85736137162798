import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  redirect,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/remix";
import { useEffect } from "react";

import * as gtag from "~/.client/gtags";
import { GoogleAnalytics } from "~/components/google-analytics";
import { Header } from "~/components/header";
import { Footer } from "~/components/footer";
import { AppContext } from "~/shared/app-context";
import { FormName } from "~/shared/form-names";
import { handleNewsletterSignup } from "~/shared/integrations/slack";
import "~/tailwind.css";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export const action = async ({ request }) => {
  const formData = await request.formData();
  const formName = formData.get("formName");

  switch (formName) {
    case FormName.NEWSLETTER_SIGNUP:
      return handleNewsletterSignup(request, formData);
    default:
      console.log('no form name found');
      return null;
  }
};

export const loader = async ({
  request,
}: LoaderFunctionArgs) => {
  const isBot = request.headers.get("x-is-bot");
  const url = new URL(request.url);
  const q = url.searchParams.get("ql") || url.searchParams.get("qs");

  if (q && q.length > 2) {
    return redirect(`/search/${q}`);
  }

  const gaTrackingId = process.env.GA_TRACKING_ID;
  return json({ isBot, gaTrackingId });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>("root");
  const location = useLocation();
  const { isBot, gaTrackingId } = data || {};
  const context = {
    isBot: isBot === 'true',
  };

  useEffect(() => {
    if (gtag && gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Header />
        <div className="mt-16"> {/* Add top margin to account for fixed header */}
          <AppContext.Provider value={context}>
            {children}
          </AppContext.Provider>
        </div>
        <Footer />
        <ScrollRestoration />
        <Scripts />
        <SpeedInsights/>
        <Analytics/>
        <GoogleAnalytics gaTrackingId={gaTrackingId} />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

const ErrorComponent = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="page-container">
      <div className="recipe-container recipe max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-6 text-gray-700">On no! I dropped my spatula!</h1>
        <hr />
        {children}
      </div>
    </div>
  )
};

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <ErrorComponent>
          <p>
            {error.status} {error.statusText}
          </p>
          <p>{error.data.message}</p>
        </ErrorComponent>
      </>
    );
  } else if (error instanceof Error) {
    return (
      <>
        <ErrorComponent>
          <p>{error.message}</p>
        </ErrorComponent>
      </>
    );
  } else {
    return (
      <>
        <ErrorComponent>
          <p>An unexpected error occurred.</p>
        </ErrorComponent>
      </>
    );
  }
}